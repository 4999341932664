import React, { useRef, useState, useEffect } from 'react'
import LocationAutocomplete from './LocationAutocomplete'
import { fetch } from 'whatwg-fetch'

const FEATURE_DATA_URL = `https://api.mapbox.com/datasets/v1/dispatchit/ckd8z4t7w52y926mhft6ujhu2/features?access_token=pk.eyJ1IjoiZGlzcGF0Y2hpdCIsImEiOiJja2Q0dGVzaXgxZHd4MnhudnhldjJjcXV0In0.ZxOaGoipIoTqwKhT-ncIkw`
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiZGlzcGF0Y2hpdCIsImEiOiJja2Q0dGVzaXgxZHd4MnhudnhldjJjcXV0In0.ZxOaGoipIoTqwKhT-ncIkw'
const MAPBOX_STYLES_URI = 'mapbox://styles/dispatchit/ckdad7kuu0rip1ins2z6tjmen'
const MAPBOX_DEFAULT_CENTER = [-98, 40.5]
const MAPBOX_DEFAULT_ZOOM = (screen.width < 480 ? 2.8 : 4)
const DEFAULT_FLYOUT_STATE = { isActive: false, heading: '', message: '', buttonAlpha: null, buttonBeta: null }

const measureDistanceBetweenPoints = (lngLat1, lngLat2) => {
  const calculateRadius = (num) => num * Math.PI / 180;
  const { lat: lat1, lng: lng1 } = lngLat1;
  const { lat: lat2, lng: lng2 } = lngLat2;

  const a = Math.sin(calculateRadius(lat2 - lat1) / 2) *
    Math.sin(calculateRadius(lat2 - lat1) / 2) +
    Math.sin(calculateRadius(lng2 - lng1) / 2) *
    Math.sin(calculateRadius(lng2 - lng1) / 2) *
    Math.cos(calculateRadius(lat1)) *
    Math.cos(calculateRadius(lat2));
  const b = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return b * 6371;
};

const AvailabilityMap = () => {
  const [features, setFeatures] = useState(null)
  const [flyout, setFlyout] = useState(DEFAULT_FLYOUT_STATE)
  const [map, setMap] = useState(null)
  const [popup, setPopup] = useState(null)
  const mapContainer = useRef(null)

  useEffect(() => {
    (function fetchAndSetFeaturesData() {
      if (features) return;

      fetch(FEATURE_DATA_URL)
        .then(res => res.json())
        .then(json => setFeatures(json.features))
        .catch(e => console.error(e))
    }())
  }, [features])

  useEffect(() => {
    (function handleMapRefSetCurrent() {
      if (map) return

      mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN

      const mapInit = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAPBOX_STYLES_URI,
        center: MAPBOX_DEFAULT_CENTER,
        zoom: MAPBOX_DEFAULT_ZOOM,
        bearing: 0,
        pitch: 0,
        scrollZoom: false
      })

      const popupInit = new mapboxgl.Popup({ closeOnClick: false });

      setPopup(popupInit)

      const handleMapLoad = () => {
        setMap(mapInit)
        mapInit.resize()
      }

      const handleMapOnMouseEnter = (event) => {
        mapInit.getCanvas().style.cursor = 'pointer'

        let coordinates = event.features[0].geometry.coordinates.slice()

        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360
        }

        popupInit
          .setLngLat(coordinates)
          .setHTML(event.features[0].properties.place_name)
          .addTo(mapInit)
      }

      const handleMapOnMouseLeave = () => {
        mapInit.getCanvas().style.cursor = ''
        popupInit.remove()
      }

      const handleMapOnClick = (event) => {
        const featuresResult = mapInit
          .queryRenderedFeatures(event.point, {
            layers: ['cities']
          });

        if (featuresResult.length < 1) {
          setFlyout(DEFAULT_FLYOUT_STATE)
          mapInit.flyTo({
            center: MAPBOX_DEFAULT_CENTER,
            zoom: (screen.width < 480 ? 2.8 : 4),
            essential: true
          })

          return;
        }

        setFlyout({
          isActive: true,
          heading: featuresResult[0].properties.place_name,
          message: `Dispatch serves <em>${featuresResult[0].properties.place_name}</em> and the surrounding areas within a 50 mile radius. Our locally established network of drivers allow us to meet our customers' delivery needs.`,
          buttonAlpha: {
            href: 'https://app.dispatchit.com/begin/sign_up',
            value: 'Start a Delivery'
          },
          buttonBeta: featuresResult[0].properties.market_url ? {
            href: `https://dispatchit.com/market/${featuresResult[0].properties.market_url}`,
            value: 'Learn more'
          } : null
        })

        mapInit.flyTo({
          center: [event.lngLat.lng, event.lngLat.lat],
          zoom: 9,
          essential: true
        })
      }

      const handleMapOnTouchEnd = (event) => {
        mapInit.off('mouseenter', 'cities', handleMapOnMouseEnter);
        mapInit.off('mouseleave', 'cities', handleMapOnMouseLeave);
        mapInit.off('click', handleMapOnClick);

        handleMapOnClick(event);
      }

      mapInit.on('load', handleMapLoad)
      mapInit.on('click', handleMapOnClick)
      mapInit.on('mouseenter', 'cities', handleMapOnMouseEnter)
      mapInit.on('mouseleave', 'cities', handleMapOnMouseLeave)
      mapInit.on('touchend', handleMapOnTouchEnd)
      mapInit.on('touchcancel', handleMapOnTouchEnd)
    }())
  }, [setMap, mapContainer, setPopup]);


  const handleLocationSelect = (inputValue, item) => {
    const feature = features.find((feature) =>
      measureDistanceBetweenPoints({
        lat: item.geometry.location.lat(),
        lng: item.geometry.location.lng()
      }, {
        lat: feature.geometry.coordinates[1],
        lng: feature.geometry.coordinates[0]
      }) <= 80) || null

    map.flyTo({
      center: [
        item.geometry.location.lng(),
        item.geometry.location.lat()
      ],
      zoom: 9,
      essential: true
    })

    if (!feature) {
      setFlyout({
        isActive: true,
        heading: `We don\'t serve this area yet.`,
        buttonAlpha: {
          href: 'https://dispatchit.com/contact',
          value: 'Request My Area'
        },
        buttonBeta: null
      })

      return
    }

    setFlyout({
      isActive: true,
      heading: feature.properties.place_name,
      message: `Dispatch serves <em>${feature.properties.place_name}</em> and the surrounding areas within a 50 mile radius. Our locally established network of drivers allow us to meet our customers' delivery needs.`,
      buttonAlpha: {
        href: 'https://app.dispatchit.com/begin/sign_up',
        value: 'Start a Delivery'
      },
      buttonBeta: feature.properties.market_url ? {
        href: `https://dispatchit.com/market/${feature.properties.market_url}`,
        value: 'Learn more'
      } : null
    })
  }

  const handleMenuRender = () => {
    if (!flyout.isActive) return;

    setFlyout({
      ...flyout,
      isActive: false
    })
  }

  return (
    <div className='availability_map'>
      <div className='availability_map__control'>
        <h2 className='availability_map__heading'>
          Dispatch delivers in cities across the country. Find a market near you.
        </h2>
        <div className='availability_map__field'>
          <LocationAutocomplete
            inputProps={{
              tabIndex: 1,
              name: "pickup_address_formatted",
              placeholder: 'Pickup location'
            }}
            inputClasses="availability_map__input"
            savedLocations={[]}
            recentLocations={[]}
            onRenderMenu={handleMenuRender}
            onSelect={handleLocationSelect}
          />
        </div>
        {flyout.isActive && (
          <div className='availability_map__flyout'>
            <h3 className='availability_map__subheading'>{flyout.heading}</h3>
            <p className='availability_map__message' dangerouslySetInnerHTML={{ __html: flyout.message }} />
            <div className='availability_map__buttons'>
              {flyout.buttonAlpha && (
                <a
                  href={flyout.buttonAlpha.href}
                  className='availability_map__button availability_map__button--alpha'>
                  {flyout.buttonAlpha.value}
                </a>
              )}
              {flyout.buttonBeta && (
                <a
                  href={flyout.buttonBeta.href}
                  className='availability_map__button availability_map__button--beta'>
                  {flyout.buttonBeta.value}
                </a>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        ref={el => (mapContainer.current = el)}
        className='availability_map__figure mapboxgl-map' />
      <style jsx global>{`
        .availability_map {
          position: relative;
          width: 100%;
        }

        .availability_map::before {
          content: '';
          display: block;
          width: 100%;
          z-index: -1000;
          padding-top: 872px;
        }

        .availability_map * {
          font-family: Nunito Sans;
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
        }

        .availability_map__figure {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 872px;
          z-index: initial;
        }

        .availability_map__control {
          position: absolute !important;
          padding-top: 64px;
          padding-left: 165px;
          left: 0;
          top: 0;
          z-index: 1;
        }

        @media (max-width: 480px) {
          .availability_map__control {
            padding: 24px 24px 24px 24px;
            align-self: center;
            align-content: center;
            position: relative;
          }
        }

        .availability_map__heading {
          line-height: 1.25;
          max-width: 489px;
          margin: 0 0 1.375rem;
          font-weight: 600;
          font-size: 24px;
          color: #FFFFFF;
          text-align: left;
        }

        .availability_map__subheading {
          margin: 0 0 0.5rem;
          line-height: 1.2;
          font-weight: 300;
          font-size: 1.25rem;
          color: #003A5D;
        }

        .availability_map__field {
          position: relative;
          margin-bottom: 0.4375rem;
          width: 100%;
          max-width: 350px;
          z-index: 1;
        }

        .availability_map__field .autocomplete {
          width: 100%;
          position: relative;
        }

        .availability_map__field .autocomplete-menu {
          position: absolute;
          background-color: white;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          z-index: 10000;
          width: 100%;
        }

        .availability_map__field .autocomplete-menu-item-contents {
          cursor: pointer;
          padding: 1rem;
          background-color: #F2F0F3;
          font-weight: 300;
          font-size: 1rem;
          line-height: 1.5;
          color: #737173;
        }

        .availability_map__input {
          width: 100%;
          padding: 1rem;
          background-color: #F2F0F3;
          border-radius: 4px;
          border-style: hidden;
          font-weight: 300;
          font-size: 1rem;
          line-height: 1.5;
          color: #737173;
        }

        .availability_map__flyout {
          z-index: 1;
          position: relative;
          max-width: 350px;
          width: 100%;
          padding: 1rem;
          background-color: #FFFFFF;
          box-shadow:
            0px 2px 4px rgba(96, 97, 112, 0.16),
            0px 0px 1px rgba(23, 22, 23, 0.04);
          border-radius: 4px;
        }

        .availability_map__flyout--visible {
          display: block;
        }

        .availability_map__flyout--hidden {
          display: none;
        }

        .availability_map__message {
          margin-bottom: 1rem;
          font-style: normal;
          font-weight: normal;
          font-size: 0.75rem;
          line-height: 1.5;
          color: #737173;
        }

        .availability_map__buttons {
          display: flex;
          justify-content: flex-end;
        }

        .availability_map__button {
          display: block;
          border-radius: 4px;
          border-width: 0;
          padding: 0.875rem 1rem;
          text-decoration: none;
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1;
          text-align: center;
          color: #FFFFFF;
          cursor: pointer;
        }

        .availability_map__button--visible {
          display: block;
        }

        .availability_map__button--hidden {
          display: none;
        }

        .availability_map__button:active {
          background: #e5e5e5;
          box-shadow: inset 0px 0px 5px #c1c1c1;
          outline: none;
        }

        .availability_map__button:last-child {
          margin-left: 1rem;
        }

        .availability_map__button--alpha {
          background: #FF8300;

        }

        .availability_map__button--beta {
          background: #003A5D;
        }

        .availability_map__figure .marker {
          width: 8;
          height: 8;
        }

        .availability_map__figure .pin {
          width: 8px;
          height: 8px;
        }

        .availability_map__figure .mapboxgl-ctrl-logo {
          display: none;
        }

        .availability_map__figure .mapboxgl-ctrl-attrib-inner {
          display: none;
        }
      `}</style>
    </div>
  )
}

export default AvailabilityMap