import React, { useState, useEffect } from 'react';
import { fetch } from 'whatwg-fetch'

const ServiceStatistics = () => {
  const [statistics, setStatistics] = useState({
    average_driver_earnings: null,
    businesses_served: null,
    miles_saved: null
  })

  useEffect(() => {
    if (Object.keys(statistics).reduce((acc, curr) => {
      if (statistics[curr] === null) acc.push(curr)
      return acc
    }, []).length > 0) {
      retrieveAndSetStatistics()
    }
  }, [statistics])

  const retrieveAndSetStatistics = () => {
    fetch('https://app.dispatchit.com/driver_stats/stats')
      .then(res => res.json())
      .then(json => setStatistics({
        ...statistics,
        ...json
      }))
      .catch(e => console.error(e))
  }

  return (
    <div id='serviceStatistics' className='service_statistics'>
      <div className='service_statistics__stat'>
        <div className='service_statistics__top'>
          <div className='service_statistics__number'>{Math.ceil(statistics.businesses_served).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        </div>
        <div className='service_statistics__bottom'>
          <div className='service_statistics__label'>Businesses Served</div>
        </div>
      </div>
      <div className='service_statistics__stat'>
        <div className='service_statistics__top'>
          <div className='service_statistics__number'>{Math.ceil(statistics.miles_saved).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
        </div>
        <div className='service_statistics__bottom'>
          <div className='service_statistics__label'>Customer Miles Saved</div>
          <div className='service_statistics__info'>
            <div className='service_statistics__info-icon'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd' d='M0 12C0 5.37258 5.37113 0 12 0C18.6274 0 24 5.37113 24 12C24 18.6274 18.6289 24 12 24C5.37258 24 0 18.6289 0 12ZM13.3333 10.6366C13.3333 10.2274 13.2064 9.90791 12.9524 9.67808C12.6984 9.44825 12.3761 9.33333 11.9853 9.33333C11.5946 9.33333 11.2772 9.44825 11.033 9.67808C10.7888 9.90791 10.6667 10.2498 10.6667 10.7039V17.2709C10.6667 17.7193 10.7888 18.0641 11.033 18.3051C11.2772 18.5461 11.5946 18.6667 11.9853 18.6667C12.3761 18.6667 12.6984 18.549 12.9524 18.3135C13.2064 18.0781 13.3333 17.7305 13.3333 17.2709V10.6366ZM12.0147 7.94139C12.3728 7.94139 12.6821 7.82581 12.9426 7.59463C13.2031 7.36345 13.3333 7.04274 13.3333 6.63248C13.3333 6.2092 13.1998 5.88686 12.9328 5.66545C12.6659 5.44404 12.3598 5.33333 12.0147 5.33333C11.6565 5.33333 11.3423 5.45543 11.072 5.69963C10.8018 5.94384 10.6667 6.25478 10.6667 6.63248C10.6667 7.04925 10.7985 7.37159 11.0623 7.59951C11.326 7.82743 11.6435 7.94139 12.0147 7.94139Z' fill='#2A282A'>
                </path>
              </svg>
            </div>
            <div className='service_statistics__info-text'>
              <span>
                <svg width='12' height='12' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path fillRule='evenodd' clipRule='evenodd' d='M0 12C0 5.37258 5.37113 0 12 0C18.6274 0 24 5.37113 24 12C24 18.6274 18.6289 24 12 24C5.37258 24 0 18.6289 0 12ZM13.3333 10.6366C13.3333 10.2274 13.2064 9.90791 12.9524 9.67808C12.6984 9.44825 12.3761 9.33333 11.9853 9.33333C11.5946 9.33333 11.2772 9.44825 11.033 9.67808C10.7888 9.90791 10.6667 10.2498 10.6667 10.7039V17.2709C10.6667 17.7193 10.7888 18.0641 11.033 18.3051C11.2772 18.5461 11.5946 18.6667 11.9853 18.6667C12.3761 18.6667 12.6984 18.549 12.9524 18.3135C13.2064 18.0781 13.3333 17.7305 13.3333 17.2709V10.6366ZM12.0147 7.94139C12.3728 7.94139 12.6821 7.82581 12.9426 7.59463C13.2031 7.36345 13.3333 7.04274 13.3333 6.63248C13.3333 6.2092 13.1998 5.88686 12.9328 5.66545C12.6659 5.44404 12.3598 5.33333 12.0147 5.33333C11.6565 5.33333 11.3423 5.45543 11.072 5.69963C10.8018 5.94384 10.6667 6.25478 10.6667 6.63248C10.6667 7.04925 10.7985 7.37159 11.0623 7.59951C11.326 7.82743 11.6435 7.94139 12.0147 7.94139Z' fill='#2A282A'>
                  </path>
                </svg>
              </span>
                This includes pickup and drop-off and return trip.
              </div>
          </div>
        </div>
      </div>
      <div className='service_statistics__stat'>
        <div className='service_statistics__top'>
          <div className='service_statistics__number'>${statistics.average_driver_earnings}</div>
        </div>
        <div className='service_statistics__bottom'>
          <div className='service_statistics__label'>Average Driver Pay</div>
          <div className='service_statistics__info'>
            <div className='service_statistics__info-icon'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd' d='M0 12C0 5.37258 5.37113 0 12 0C18.6274 0 24 5.37113 24 12C24 18.6274 18.6289 24 12 24C5.37258 24 0 18.6289 0 12ZM13.3333 10.6366C13.3333 10.2274 13.2064 9.90791 12.9524 9.67808C12.6984 9.44825 12.3761 9.33333 11.9853 9.33333C11.5946 9.33333 11.2772 9.44825 11.033 9.67808C10.7888 9.90791 10.6667 10.2498 10.6667 10.7039V17.2709C10.6667 17.7193 10.7888 18.0641 11.033 18.3051C11.2772 18.5461 11.5946 18.6667 11.9853 18.6667C12.3761 18.6667 12.6984 18.549 12.9524 18.3135C13.2064 18.0781 13.3333 17.7305 13.3333 17.2709V10.6366ZM12.0147 7.94139C12.3728 7.94139 12.6821 7.82581 12.9426 7.59463C13.2031 7.36345 13.3333 7.04274 13.3333 6.63248C13.3333 6.2092 13.1998 5.88686 12.9328 5.66545C12.6659 5.44404 12.3598 5.33333 12.0147 5.33333C11.6565 5.33333 11.3423 5.45543 11.072 5.69963C10.8018 5.94384 10.6667 6.25478 10.6667 6.63248C10.6667 7.04925 10.7985 7.37159 11.0623 7.59951C11.326 7.82743 11.6435 7.94139 12.0147 7.94139Z' fill='#2A282A' />
              </svg>
            </div>
            <div className='service_statistics__info-text'>
              <span>
                <svg width='12' height='12' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path fillRule='evenodd' clipRule='evenodd' d='M0 12C0 5.37258 5.37113 0 12 0C18.6274 0 24 5.37113 24 12C24 18.6274 18.6289 24 12 24C5.37258 24 0 18.6289 0 12ZM13.3333 10.6366C13.3333 10.2274 13.2064 9.90791 12.9524 9.67808C12.6984 9.44825 12.3761 9.33333 11.9853 9.33333C11.5946 9.33333 11.2772 9.44825 11.033 9.67808C10.7888 9.90791 10.6667 10.2498 10.6667 10.7039V17.2709C10.6667 17.7193 10.7888 18.0641 11.033 18.3051C11.2772 18.5461 11.5946 18.6667 11.9853 18.6667C12.3761 18.6667 12.6984 18.549 12.9524 18.3135C13.2064 18.0781 13.3333 17.7305 13.3333 17.2709V10.6366ZM12.0147 7.94139C12.3728 7.94139 12.6821 7.82581 12.9426 7.59463C13.2031 7.36345 13.3333 7.04274 13.3333 6.63248C13.3333 6.2092 13.1998 5.88686 12.9328 5.66545C12.6659 5.44404 12.3598 5.33333 12.0147 5.33333C11.6565 5.33333 11.3423 5.45543 11.072 5.69963C10.8018 5.94384 10.6667 6.25478 10.6667 6.63248C10.6667 7.04925 10.7985 7.37159 11.0623 7.59951C11.326 7.82743 11.6435 7.94139 12.0147 7.94139Z' fill='#2A282A' />
                </svg>
              </span>
                This is the average earnings of a cargo van driver who has taken five or more orders in a day.
              </div>
          </div>
        </div>
      </div>
      <style jsx global>{`
        .service_statistics {
          display: flex;
          flex-direction: column;
          padding-top: 4rem;
          padding-bottom: 4rem;
        }

        @media only screen and (min-width: 768px) {
          .service_statistics {
            padding-bottom: 6rem;
            flex-direction: row;
            justify-content: space-around;
          }
        }

        @media only screen and (min-width: 1110px) {
          .service_statistics {
            padding-bottom: 7.5rem;
          }
        }

        .service_statistics__stat {
          margin-bottom: 2rem;
        }

        @media only screen and (min-width: 768px) {
          .service_statistics__stat {
            margin-bottom: 0
          }
        }

        /* .service_statistics__top {} */

        .service_statistics__number {
          font-family: Nunito Sans;
          font-weight: 800;
          text-align: center;
          color: #003A5D;
          line-height: 1.25;
          font-size: 2.125rem
        }

        @media only screen and (min-width: 768px) {
          .service_statistics__number {
            font-size: 2.75rem;
          }
        }

        @media only screen and (min-width: 1110px) {
          .service_statistics__number {
            font-size: 3.25rem;
          }
        }

        .service_statistics__bottom {
          text-align: center;
        }

        .service_statistics__label {
          display: inline-block;
          font-family: Nunito Sans;
          font-weight: 300;
          line-height: 1.5;
          font-size: calc(2.125rem * 0.375);
          color: #737173;
        }

        @media only screen and (min-width: 768px) {
          .service_statistics__label {
            font-size: calc(2.75rem * 0.375);
          }
        }

        @media only screen and (min-width: 1110px) {
          .service_statistics__label {
            font-size: calc(3.25rem * 0.375);
          }
        }

        .service_statistics__info {
          display: inline-block;
          position: relative;
          margin-left: 0.25rem;
          font-size: calc(2.125rem * 0.25);
        }

        @media only screen and (min-width: 768px) {
          .service_statistics__info {
            font-size: calc(2.75rem * 0.25);
          }
        }

        @media only screen and (min-width: 1110px) {
          .service_statistics__info {
            font-size: calc(3.25rem * 0.25);
          }
        }

        .service_statistics__info-icon {
          width: 1em;
        }

        .service_statistics__info-icon svg {
          max-width: 100%;
        }

        .service_statistics__info-icon:hover+.service_statistics__info-text {
          display: block;
        }

        .service_statistics__info-text {
          display: none;
          position: absolute;
          top: 100%;
          right: calc(100% - 1rem);
          min-width: 35ch;
          position: absolute;
          margin-top: 0.625rem;
          padding: 1.25rem;
          border-radius: 8px;
          background-color: #fff;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
          font-family: 'Nunito Sans', sans-serif;
          color: #737173;
          line-height: 1.5;
          font-weight: 600;
          text-align: left;
        }

        .service_statistics__info-text span {
          margin-right: 0.375rem;
        }
      `}</style>
    </div>
  )
}

export default ServiceStatistics