import React, { useEffect, useState } from 'react';
import { markets } from './../constants'
import { fetch } from 'whatwg-fetch'

const DriverEstimateForm = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [formState, setFormState] = useState({
    market: null,
    hours: null,
    estimate: null,
    isNewMarket: null
  })

  const [servicePayload, setServicePayload] = useState({
    market: null,
    hours: null
  })

  const handleSelectChange = (event) => {
    const name = event.target.name
    const value = name === 'market' ? event.target.value : parseInt(event.target.value)

    setFormState({
      ...formState,
      [name]: value
    })

    setServicePayload({
      ...servicePayload,
      [name]: value === '' ? null : value
    })
  }

  useEffect(() => {
    (function handleServicePayloadStateComplete(servicePayload) {
      if (Object.keys(servicePayload).reduce((acc, curr, idx) => {
        if (servicePayload[curr] == null) acc.push(curr)
        return acc
      }, []).length === 0) {
        retrieveAndSetEstimate()
      } else {
        setFormState({
          ...formState,
          estimate: null
        })
      }
    }(servicePayload))
  }, [servicePayload])

  const retrieveAndSetEstimate = () => {
    setFormState({
      ...formState,
      estimate: null
    })

    setIsLoading(true)

    fetch('https://app.dispatchit.com/driver_stats/driver_estimate', {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(servicePayload)
    })
      .then(res => res.json())
      .then(json => {
        setFormState({
          ...formState,
          estimate: `$${json.estimated_earnings}`,
          isNewMarket: json.is_new_market
        })
        setIsLoading(false)
      })
      .catch(e => {
        console.error(e)
        setFormState({
          ...formState,
          estimate: null
        })
        setIsLoading(false)
      })
  }

  const handleSubmit = (event) => {
    if (!formState.estimate) {
      event.preventDefault()
      return
    }

    window.location = 'https://www.fountain.com/c/dispatch-llc#/'
  }

  const themeClasses = theme.map(t => `estimate_form--${t}`).join(' ')

  return (
    <div className={`estimate_form ${themeClasses}`}>
      <div className='estimate_form__content'>
        <div className='estimate_form__header'>
          <h2 className='estimate_form__heading'>
            Driver Calculator
          </h2>
          <p className='estimate_form__message'>
            Want to know how much you could make as a Dispatch driver? Fill out the form for an estimate. Then sign up to drive and join a community of pro drivers!
          </p>
          {formState.isNewMarket && (
            <p className='estimate_form__message'>
              This is a new market. The total displayed is based off national averages.
            </p>
          )}
        </div>
        <form
          onSubmit={handleSubmit}
          className='estimate_form__form'>
          <div className='estimate_form__fields estimate_form__fields--1-2'>
            <div className='estimate_form__field'>
              <select
                onChange={handleSelectChange}
                className='estimate_form__input estimate_form__input--select'
                name='market'>
                <option value=''>Select market</option>
                {markets.map((market, index) => <option key={index} value={market}>{market}</option>)}
              </select>
            </div>
            <div className='estimate_form__field'>
              <input
                onChange={handleSelectChange}
                className='estimate_form__input estimate_form__input--number'
                name='hours'
                type='number'
                placeholder='Hours'
                min='0' />
            </div>
          </div>
          {isLoading && (
            <div className='estimate_form__field estimate_form__field--thinking' />
          )}
          {formState.estimate && (
            <>
              <div className='estimate_form__field estimate_form__field--figure'>
                <input
                  className='estimate_form__input estimate_form__input--number estimate_form__input--readonly'
                  name='estimate'
                  type='text'
                  value={formState.estimate}
                  readOnly />
              </div>
              <div className='estimate_form__field estimate_form__field--submit'>
                <input
                  className='estimate_form__input estimate_form__input--submit'
                  type='submit'
                  value='Sign Up to Drive' />
              </div>
            </>
          )}
        </form>
      </div>
      <div className='estimate_form__background'>
        {theme.includes('dark') && (
          <svg viewBox="0 0 984 844">
            <g style={{ mixBlendMode: 'overlay' }} opacity="0.3">
              <path d="M832.303 286.605L1036.09 -16.8556L765.298 -178L601.384 153.149L437.471 -178L167.236 -20.1781L370.466 286.605L0 265.563V578.438L370.466 556.841L163.913 863.624L434.149 1018.68L601.384 687.528L765.298 1022L1036.09 863.624L832.303 556.841L1200 581.206V265.563L832.303 286.605Z" fill="#003A5D" />
            </g>
          </svg>
        )}
        {theme.includes('bright') && (
          <svg width='680' height='460' viewBox='0 0 680 460' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M398.099 356.198L554.333 123.544L346.728 0L221.061 253.881L95.3945 0L-111.786 120.997L44.024 356.198L-240 340.065V579.935L44.024 563.378L-114.333 798.579L92.8473 917.453L221.061 663.572L346.728 920L554.333 798.579L398.099 563.378L680 582.058V340.065L398.099 356.198Z' fill='#FAF9FA' />
          </svg>
        )}
      </div>
      <style jsx global>{`
        .estimate_form {
          width: 100%;
          position: relative;
          overflow: hidden;
          padding-top: 4rem;
          padding-bottom: 4rem;
          padding-left: 1rem;
          padding-right: 1rem;
          box-sizing: border-box;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form {
            padding-top: 6rem;
            padding-bottom: 6rem;
          }
        }

        @media only screen and (min-width: 1110px) {
          .estimate_form {
            padding-top: 7.5rem;
            padding-bottom: 7.5rem;
          }
        }

        .estimate_form * {
          font-family: Nunito Sans;
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
        }

        .estimate_form input,
        .estimate_form select {
          -webkit-appearance: none;
          -moz-appearance: none;
        }

        .estimate_form__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          max-width: 1110px;
          margin: 0 auto;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form--horizontal .estimate_form__content {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
          }
        }

        .estimate_form__header {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 3.75rem;
          text-align: center;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form--horizontal .estimate_form__header {
            margin-bottom: 0;
            text-align: left;
            align-items: flex-start;
          }
        }

        .estimate_form--bright .estimate_form__header * {
          color: #003A5D;
        }

        .estimate_form--dark .estimate_form__header * {
          color: white;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form--lateral .estimate_form__header {
            margin-bottom: 4.5rem;
          }

          .estimate_form--horizontal .estimate_form__header {
            width: 42%;
          }
        }

        @media only screen and (min-width: 1110px) {
          .estimate_form--lateral .estimate_form__header {
            margin-bottom: 6rem;
          }
        }

        .estimate_form__heading {
          font-weight: 800;
          font-size: 1.75rem;
          line-height: 1.2;
          margin: 0 0 1rem 0;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form__heading {
            font-size: 2.125rem;
          }
        }

        @media only screen and (min-width: 1110px) {
          .estimate_form__heading {
            font-size: 2.5rem;
          }
        }

        .estimate_form__message {
          font-size: 0.875rem;
          max-width: 60ch;
          font-weight: 300;
          line-height: 1.5;
          margin: 0;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form__message {
            font-size: calc(2.125rem * 0.4);
          }
        }

        @media only screen and (min-width: 1110px) {
          .estimate_form__message {
            font-size: calc(2.5rem * 0.4);
          }
        }

        .estimate_form__form {
          width: 100%;
          max-width: 36rem;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form--horizontal .estimate_form__form {
            max-width: 50%;
          }
        }

        .estimate_form__fields {
          display: flex;
        }

        .estimate_form__fields>*:not(:last-child) {
          margin-right: 1rem;
        }

        .estimate_form__fields--1-2>*:first-child {
          width: calc(100% * 2/3);
        }

        .estimate_form__fields--1-2>*:last-child {
          width: calc(100% * 1/3);
        }

        .estimate_form__field {
          width: 100%;
          position: relative;
          margin-bottom: 1rem;
        }

        .estimate_form__input {
          width: 100%;
          padding: 1rem;
          background-color: #F2F0F3;
          border-radius: 4px;
          border-style: hidden;
          font-weight: 300;
          font-size: 1rem;
          line-height: 1.5;
          color: #737173;
        }

        .estimate_form__field--select {
          background-size: 1rem;
          background-position: calc(100% - 1rem) 50%;
          background-repeat: no-repeat;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACgSURBVHgB5dLBDcIwDEBRO+oAHYWNaDcITu+wQANM0DBJV2EEJrCJEUKCNmm4tv8QWzk85RCA9eUObg9/RpYaa22tuyGic5zBkRtKgc52R0QcKqzGF8LMNwF5gEBTAikgKCfdWfiqE/WIz9oZNCMC1vEm9L5vlwARaf3Fhw9SAqWALyQH5YAJMgch4z0HzCIT6F0KSCK/UA5YTCH9VLCtnvrta4eCilyPAAAAAElFTkSuQmCC);
        }

        .estimate_form__field--figure {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        .estimate_form__input--readonly {
          background-color: transparent;
          font-weight: 800;
          text-align: center;
          font-size: 2.5rem;
          outline: none;
          cursor: default;
        }

        .estimate_form--bright .estimate_form__input--readonly {
          color: #737173;
        }

        .estimate_form--dark .estimate_form__input--readonly {
          color: #FFFFFF;
        }

        .estimate_form__input--submit {
          width: 100%;
          padding: 1rem;
          font-weight: 300;
          font-size: 1.125rem;
          line-height: 1.5;
          border: none;
          border-radius: 4px;
          color: #FFFFFF;
        }

        .estimate_form__input--submit:enabled {
          cursor: pointer;
          visibility: visible;
        }

        .estimate_form__input--submit[disabled] {
          visibility: hidden;
        }

        .estimate_form--bright .estimate_form__input--submit {
          background: #003A5D;
        }

        .estimate_form--dark .estimate_form__input--submit {
          background: #0072ED;
        }

        @keyframes thinking {
          0% {
            content: '.'
          }

          50% {
            content: '..'
          }

          100% {
            content: '...'
          }
        }

        .estimate_form__field--thinking::before {
          content: '...';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          padding: 1rem;
          border-radius: 4px;
          border-style: hidden;
          font-weight: 300;
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 800;
          text-align: center;
          font-size: 2.5rem;
          animation: thinking 1s ease-in-out infinite;
        }

        .estimate_form--bright .estimate_form__field--thinking::before {
          color: #737173;
        }

        .estimate_form--dark .estimate_form__field--thinking::before {
          color: #FFF;
        }

        .estimate_form__background {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: -1;
        }

        .estimate_form__background--bright .estimate_form__background {
          background-color: #FFFFFF;
        }

        .estimate_form--dark .estimate_form__background {
          background-color: #003A5D;
        }

        .estimate_form__background>svg {
          position: absolute;
        }

        .estimate_form--horizontal .estimate_form__background>svg {
          height: 110%;
          bottom: -15%;
          left: -100%;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form--horizontal .estimate_form__background>svg {
            left: -15%;
            bottom: 0;
          }
        }

        @media only screen and (min-width: 1110px) {
          .estimate_form--horizontal .estimate_form__background>svg {
            width: 100%;
            height: auto;
            top: -15%;
            right: -10%;
          }
        }

        .estimate_form--lateral .estimate_form__background>svg {
          height: 110%;
          top: -5%;
          right: -85%;
        }

        @media only screen and (min-width: 768px) {
          .estimate_form--lateral .estimate_form__background>svg {
            right: -15%;
          }
        }

        @media only screen and (min-width: 1110px) {
          .estimate_form--lateral .estimate_form__background>svg {
            width: 100%;
            height: auto;
            top: -15%;
            right: -10%;
          }
        }
      `}</style>
    </div >
  )
}

export default DriverEstimateForm
