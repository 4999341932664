import ReactDOM from 'react-dom';
import React from 'react'
import DriverEstimateForm from './components/DriverEstimateForm'
import AvailabilityMap from './components/AvailabilityMap'
import CustomerEstimateForm from './components/CustomerEstimateForm'
import ServiceStatistics from './components/ServiceStatistics'

// Object.assign polyfill
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target === null || target === undefined) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource !== null && nextSource !== undefined) {
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

var customerEstimateFormDarkLateralMountNode = document.getElementById("customerEstimateFormDarkLateral");
var customerEstimateFormBrightHorizontalMountNode = document.getElementById("customerEstimateFormBrightHorizontal");
var driverEstimateFormDarkLateralMountNode = document.getElementById("driverEstimateFormDarkLateral");
var driverEstimateFormBrightHorizontalMountNode = document.getElementById("driverEstimateFormBrightHorizontal");
var serviceStatisticsMountNode = document.getElementById("serviceStatistics");
var availabilityMapMountNode = document.getElementById("availabilityMap");

if (customerEstimateFormBrightHorizontalMountNode) {
  ReactDOM.render(<CustomerEstimateForm theme={['bright', 'horizontal']} />, customerEstimateFormBrightHorizontalMountNode);
}

if (customerEstimateFormDarkLateralMountNode) {
  ReactDOM.render(<CustomerEstimateForm theme={['dark', 'lateral']} />, customerEstimateFormDarkLateralMountNode);
}

if (driverEstimateFormBrightHorizontalMountNode) {
  ReactDOM.render(<DriverEstimateForm theme={['bright', 'horizontal']} />, driverEstimateFormBrightHorizontalMountNode);
}

if (driverEstimateFormDarkLateralMountNode) {
  ReactDOM.render(<DriverEstimateForm theme={['dark', 'lateral']} />, driverEstimateFormDarkLateralMountNode);
}

if (serviceStatisticsMountNode) {
  ReactDOM.render(<ServiceStatistics />, serviceStatisticsMountNode);
}

if (availabilityMapMountNode) {
  ReactDOM.render(<AvailabilityMap />, availabilityMapMountNode);
}
